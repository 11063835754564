import React, { useState } from "react"
import IMAGES from "../images"
import Social from "./Social"
import "./Slider.css"
import OrderModal from "./OrderModal"
import PlayVideo from "./PlayVideo"
import ReactGA from "react-ga"
import { Fragment } from "react/cjs/react.production.min"

const Slider = () => {
	const [openModal, setOpenModal] = useState(false)
	const [openVideo, setOpenVideo] = useState(false)
	const Analytics = () => {
		ReactGA.event({
			category: "Bouton",
			action: "Action pour placer une commande",
		})
	}

	return (
		<Fragment>
			<div className="container">
				<div className="divider" />
				<div className="content">
					<Social />

					<div className="section">
						<div className="bg">
							<img src={IMAGES.productBg} alt="" />
						</div>

						<h1 className="description">
							Les sabots de voiture à Rabat & Casablanca <br />
							<strong> Ce n'est plus un problème !</strong>
						</h1>
						<div className="row order-buttons vcenter">
							<div
								className="row vcenter shop-now"
								onClick={(e) => {
									setOpenModal(true)
									Analytics()
								}}
							>
								<div className="text">Commander</div>
								<div className="price">
									{/* <div className="unit">30 DH</div> */}
									<div className="shipping">
										Livraison rapide
									</div>
								</div>
							</div>

							<OrderModal
								open={openModal}
								hideDialog={() => setOpenModal(false)}
							/>
							<div
								className="row vcenter play-video"
								onClick={() => setOpenVideo(true)}
							>
								<img
									className="icon"
									src={IMAGES.playIcon}
									alt=""
								/>
								<div className="text">Voir le produit</div>
							</div>
							<PlayVideo
								open={openVideo}
								hideDialog={() => setOpenVideo(false)}
							/>
						</div>
					</div>
				</div>
				<div className="divider" />
			</div>
		</Fragment>
	)
}

export default Slider
