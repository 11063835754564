import emailJs, { init } from "@emailjs/browser"
import { orderStatuses } from "../../constants"

export const initEmailJs = () => {
	init("user_97BdGxwkLoVBcgBc8FKbf")
}

export const sendEmail = (templateId, templateParams) => {
	emailJs.send("service_cf2fcks", templateId, templateParams).then(
		function (response) {},
		function (error) {}
	)
}
export const orderStatusChangeTemplate = (status) => {
	if (status === orderStatuses.delivered) {
		return deliveredOrderTemplateID
	}
	if (status === orderStatuses.canceled) {
		return cancelOrderTemplateID
	} else {
		return null
	}
}

export const createOrderTemplateID = "template_ijvexbg"
export const createRetraitTemplateID = "template_vdxi757"
export const deliveredOrderTemplateID = "template_xs9919u"
//TODO ADD_TEMPLATE_ID
export const cancelOrderTemplateID = "ADD_TEMPLATE_ID"
