import React, { useState } from "react"
import { supabase } from "../client"
import { useNavigate } from "react-router-dom"
import "./Auth.css"
import ToastMessage from "../ToastMessage"
import { Fragment } from "react/cjs/react.production.min"

const Authentification = () => {
	const navigate = useNavigate()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const session = supabase.auth.session()
	const [user, setUser] = useState(null)

	supabase.auth.onAuthStateChange((event, session) => {
		if (session?.user) {
			setUser((u) => (u = session.user))
		}
	})

	async function signOut() {
		await supabase.auth.signOut()
		setUser((u) => (u = null))
		ToastMessage("Vous vous êtes déconnecté")
	}

	async function login(email, password, navigate, session) {
		try {
			const { error } = await supabase.auth.signIn({ email, password })
			if (error) throw error

			navigate("/ManageOrder")
		} catch (error) {
			alert(error.message)
		}
	}
	// async function signUp(email, password, navigate, session) {
	// 	try {
	// 		const { error } = await supabase.auth.signUp({ email, password })
	// 		if (error) throw error
	// 		alert("vous êtes membre desormais !!!")
	// 		navigate("/ManageOrder")
	// 	} catch (error) {
	// 		alert(error.message)
	// 	}
	// }

	return (
		<Fragment>
			<div className="auth-page">
				<ToastMessage />

				{user && <div className="header">{user.email}</div>}

				<div className="auth-form">
					<h1>Authentification</h1>

					{session === null ? (
						<Fragment>
							<input
								type="text"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>

							<div className="login-buttons">
								{/* <button
							onClick={() => signUp(email, password, navigate)}
						>
							S'inscrire
						</button> */}
								<button
									onClick={() =>
										login(email, password, navigate)
									}
								>
									Se connecter
								</button>
							</div>
						</Fragment>
					) : (
						<button onClick={() => signOut()}>
							Se Déconnecter{" "}
						</button>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default Authentification
