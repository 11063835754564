import React from "react"
import { Bars } from "react-loading-icons"
import { Fragment } from "react/cjs/react.production.min"
import "./Loader.css"

const Loader = (props) => {
	return props.visible ? (
		<div className="loader-overlay">
			<Bars fill="#5BAAFF" />
			<div className="loader-text">Traitement en cours...</div>
		</div>
	) : (
		<Fragment></Fragment>
	)
}

export default Loader
