import React from "react"
import { Dialog } from "primereact/dialog"
import ReactPlayer from "react-player"

const PlayVideo = ({ open, hideDialog }) => {
	return (
		<Dialog
			className="show-video"
			visible={open}
			header="Regardez les clés en Action !"
			onHide={() => hideDialog()}
		>
			<ReactPlayer
				url="https://fb.watch/nSc5MFbUmX/?mibextid=Nif5oz"
				height="90%"
			/>
		</Dialog>
	)
}

export default PlayVideo
