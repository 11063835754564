import React, { Component } from "react"
import IMAGES from "../images"
import "./Social.css"

class Social extends Component {
	render() {
		return (
			<div className="header">
				<div className="logo row vcenter hcenter">
					<img src={IMAGES.logo} alt="" />
				</div>

				<a
					className="row vcenter social hend pd24"
					href="https://www.facebook.com/ZEROSABOT"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={IMAGES.facebookIcon} alt="" />
					<span className="follow-us">Suivez-nous</span>
				</a>
			</div>
		)
	}
}

export default Social
