import facebookIcon from "./facebook.svg"
import logo from "./logo.svg"
import productBg from "./bg.png"
import playIcon from "./play.svg"
import pizzeria from "./pizzeria.jpg"
import delivery from "./delivery.png"
import warehouse from "./warehouse.png"

const IMAGES = {
	facebookIcon: facebookIcon,
	logo: logo,
	productBg: productBg,
	playIcon: playIcon,
	pizzeria: pizzeria,
	delivery: delivery,
	warehouse: warehouse,
}

export default IMAGES
