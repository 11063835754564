import React, { useState, useEffect, useRef } from "react"
import "./ManageOrder.css"
import { supabase } from "../client"
import "../Responsive.css"
import ToastMessage from "../ToastMessage"
import "react-toastify/dist/ReactToastify.css"
import { Fragment } from "react/cjs/react.production.min"
import Auth from "./Auth"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { confirmDialog } from "primereact/confirmdialog"

import "react-tabs/style/react-tabs.css"
import { Menu } from "primereact/menu"
import { orderStatuses, pricesMap } from "../constants"
import { orderStatusChangeTemplate, sendEmail } from "./service/EmailService"

const ManageOrder = () => {
	useEffect(() => {
		fetchOrders()
	}, [])

	const [orders, setOrders] = useState([])
	const [order, setOrder] = useState(null)
	const [user, setUser] = useState(null)
	const menu = useRef(null)

	const updateOrderStatus = (status) => {
		supabase
			.from("orders")
			.update({ status: status, shipping_date: new Date() })
			.eq("id", order.id)
			.then(() => {
				fetchOrders().then(() => {
					const templateParams = {
						idorder: order.idorder,
						quantity: order.quantity,
						email: order.email,
						price: order.quantity * pricesMap.get(order.city) + 30,
						phone: order.phone,
						adresse: order.adresse,
						city: order.city,
					}
					//sendEmail(orderStatusChangeTemplate(status), templateParams)
					ToastMessage(`La commande ${order.idorder}  est ${status}`)
				})
			})
	}

	const confirm = (status) => {
		confirmDialog({
			message: `Êtes-vous sûr de changer le statut de la commande ${order.idorder} à ${status}?`,
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => updateOrderStatus(status),
		})
	}
	const items = [
		{
			label: "Terminer",
			icon: "pi pi-fw pi-check",
			command: () => confirm(orderStatuses.delivered),
		},
		{
			label: "Annuler",
			icon: "pi pi-fw pi-times",
			command: () => confirm(orderStatuses.canceled),
		},
	]

	const session = supabase.auth.session()

	supabase.auth.onAuthStateChange((event, session) => {
		if (session?.user) {
			setUser((u) => (u = session.user))
		}
	})

	async function fetchOrders() {
		const { data } = await supabase
			.from("orders")
			.select()
			.order("created_at", { ascending: false })

		setOrders(data)
		if (session) {
			ToastMessage("Vous êtes connecté !")
		}
	}

	const getOrdersByStatus = (status) => {
		return orders.filter((item) => item.status === status)
	}
	const benefits = getOrdersByStatus(orderStatuses.delivered)?.reduce(
		(acc, order) => {
			return acc + order.quantity * pricesMap.get(order.city)
		},
		0
	)

	return (
		<Fragment>
			{session === null ? (
				<div>
					<ToastMessage text="Vous n'êtes pas autorisé" />
					<Auth />
				</div>
			) : (
				<Fragment>
					<Menu model={items} popup ref={menu} />
					<div className="order-list">
						<ToastMessage />
						<div className="row">
							<h1 className="order-list-title">
								Liste des commandes
							</h1>

							<div className="CA">
								<div className="lbl">Chiffre d'affaire</div>
								<div className="count">{benefits} DH</div>
							</div>
						</div>
						<Tabs>
							<TabList>
								<Tab>En cours</Tab>
								<Tab>Terminé</Tab>
								<Tab>Annulé</Tab>
							</TabList>
							<TabPanel>
								<OrdersDetails
									menu={menu}
									setOrder={setOrder}
									orders={getOrdersByStatus(
										orderStatuses.inprogress
									)}
								/>
							</TabPanel>
							<TabPanel>
								<OrdersDetails
									menu={menu}
									setOrder={setOrder}
									orders={getOrdersByStatus(
										orderStatuses.delivered
									)}
								/>
							</TabPanel>
							<TabPanel>
								<OrdersDetails
									menu={menu}
									setOrder={setOrder}
									orders={getOrdersByStatus(
										orderStatuses.canceled
									)}
								/>
							</TabPanel>
						</Tabs>
					</div>
				</Fragment>
			)}
		</Fragment>
	)
}

export default ManageOrder

const OrdersDetails = ({ orders, menu, setOrder }) => {
	const orderStateStyle = new Map()
	orderStateStyle.set(orderStatuses.inprogress, "status new")
	orderStateStyle.set(orderStatuses.delivered, "status shipped")
	orderStateStyle.set(orderStatuses.canceled, "status cancelled")
	return (
		<div className="order-details">
			{orders?.map((order) => (
				<div key={order.id} className="order-line-container">
					<div className="order-line">
						<div className="bloc">
							<div className="libelle">N° Commande</div>
							<div className="item reduce">{order.idorder}</div>
						</div>
						<div className="bloc">
							<div className="libelle">Date commande</div>
							<div className="item reduce">
								{new Date(
									order.created_at
								).toLocaleDateString()}
							</div>
						</div>
						<div className="bloc">
							<div className="libelle">Quantité</div>
							<div className="item reduce">{order.quantity}</div>
						</div>
						<div className="bloc">
							<div className="libelle">Client</div>
							<div className="item">{order.fullname}</div>
						</div>
						<div className="bloc">
							<div className="libelle">Téléphone</div>
							<div className="item">
								<strong>{order.phone}</strong>
							</div>
						</div>
						<div className="bloc">
							<div className="libelle">Adresse</div>
							<div className="item">{order.adresse}</div>
						</div>
						<div className="bloc">
							<div className="libelle">Montant</div>
							<div className="item">
								{order.quantity * pricesMap.get(order.city) +
									30 +
									" DH"}
							</div>
						</div>
						<div className="bloc">
							<div className="libelle">Date de livraison</div>
							<div className="item">
								{order.shipping_date
									? new Date(
											order.shipping_date
									  ).toLocaleDateString()
									: ""}
							</div>
						</div>
						<div className="bloc">
							<div className="libelle">Notes</div>
							<div className="item">
								<strong>{order.notes}</strong>
							</div>
						</div>
						<div className="bloc">
							<div className="libelle">Status</div>
							<div
								onClick={(e) => {
									if (
										order.status ===
										orderStatuses.inprogress
									) {
										menu.current.toggle(e)
										setOrder(order)
									}
								}}
								className={orderStateStyle.get(order.status)}
							>
								{order.status}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
