import * as React from "react"
import "./Checkmark.css"

const SvgComponent = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={154} height={154} {...props}>
		<g fill="none" stroke="#22AE73" strokeWidth={2}>
			<circle
				cx={77}
				cy={77}
				r={72}
				style={{
					strokeDasharray: "480px,480px",
					strokeDashoffset: 960,
				}}
			/>
			<circle
				id="colored"
				fill="#22AE73"
				cx={77}
				cy={77}
				r={72}
				style={{
					strokeDasharray: "480px,480px",
					strokeDashoffset: 960,
				}}
			/>
			<path
				className="st0"
				stroke="#fff"
				strokeWidth={10}
				style={{
					strokeDasharray: "100px,100px",
					strokeDashoffset: 200,
				}}
				d="m43.5 77.8 20.2 20.1 48.5-48.5"
			/>
		</g>
	</svg>
)

export default SvgComponent
