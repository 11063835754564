import React from "react"
import "./App.css"
import "./Responsive.css"
import Slider from "./Components/Slider"
import ManageOrder from "./Components/ManageOrder"
import Auth from "./Components/Auth"
import { Routes, Route } from "react-router-dom"

import PrimeReact from "primereact/api"
import "primeicons/primeicons.css"
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.css"
import { initEmailJs } from "./Components/service/EmailService"
import ReactGA from "react-ga"
initEmailJs()

const App = () => {
	PrimeReact.autoZIndex = true
	ReactGA.initialize("UA-220081236-1")
	ReactGA.pageview(window.location.pathname + window.location.search)

	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Slider />} />
				<Route path="/ManageOrder" element={<ManageOrder />} />
				<Route path="/Auth" element={<Auth />} />
			</Routes>
		</div>
	)
}

export default App
