import React, { useState } from "react"
import IMAGES from "../images"
import "./OrderModal.css"
import Checkmark from "./Checkmark"
import { Dialog } from "primereact/dialog"
import { supabase } from "../client"
import Loader from "./Loader"
import { Fragment } from "react/cjs/react.production.min"
import { Dropdown } from "primereact/dropdown"
import {
	createOrderTemplateID,
	createRetraitTemplateID,
	sendEmail,
} from "./service/EmailService"
import { pricesMap } from "../constants"
import "@fortawesome/fontawesome-free/css/all.min.css"

const DELIVERY_PRICE = 30
const OrderModal = ({ open, hideDialog }) => {
	const [succes, setSucces] = useState(false)
	const [fail, setFail] = useState(false)

	const emailValidation = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
	const phoneValidation = /^\d{10}$/

	const min = 100000
	const max = 999999
	const rand = min + Math.random() * (max - min)

	const [order, setOrder] = useState({
		idorder: Math.round(rand),
		fullname: "",
		phone: "",
		email: "",
		city: "Casablanca",
		adresse: "",
		quantity: 1,
		shmode: "Livraison",
	})

	const cities = ["Casablanca", "Rabat", "Casablanca + Rabat"]

	const [orderError, setOrderError] = useState({
		fullnameError: true,
		phoneError: true,
		emailError: true,
		adresseError: true,
	})
	const [loader, setLoader] = useState(false)

	const timeout = (delay) => {
		return new Promise((res) => setTimeout(res, delay))
	}

	async function createOrder() {
		// Vérifier si le numéro de téléphone existe déjà dans la table "orders"
		const { data: orders, error } = await supabase
			.from("orders")
			.select("*")
			.eq("phone", order.phone)
			.eq("status", "Annulé")

		// Si le numéro de téléphone existe déjà dans la table "orders", afficher une erreur
		if (orders.length > 0) {
			setFail(true)
			setSucces(false)
			return
		}

		setLoader(true)
		await timeout(2000)

		await supabase.from("orders").insert([order]).single()

		setOrder({
			idorder: Math.round(rand),
			fullname: "",
			phone: "",
			email: "",
			adresse: "",
			quantity: 1,
			shmode: "",
		})

		const templateParams = {
			idorder: order.idorder,
			name: order.fullname,
			quantity: order.quantity,
			email: order.email,
			price: order.quantity * pricesMap.get(order.city) + deliveryPrice,
			phone: order.phone,
			adresse: order.adresse,
			city: order.city,
			shmode: order.shmode,
		}

		sendEmail(
			order.shmode === "Livraison"
				? createOrderTemplateID
				: createRetraitTemplateID,
			templateParams
		)
		setSucces(true)
		setLoader(false)
	}

	const closeOrder = () => {
		setFail(false)
		setSucces(false)
		hideDialog(false)
	}

	const updateQuantity = (increment) => {
		setOrder({
			...order,
			quantity: order.quantity + increment,
			idorder: order.idorder,
		})
	}
	const [deliveryPrice, setDelivery] = useState(DELIVERY_PRICE)

	const isEmpty = (value) => {
		return value === undefined || value === null || value.trim() === ""
	}
	const errorCheck = () => {
		return Object.values(orderError).some((item) => item)
	}

	const onPressOpenWhatsapp = () => {
		window.open("https://wa.me/212669654316", "_blank")
	}
	return (
		<Fragment>
			<Dialog
				visible={open}
				header={
					!succes
						? "Commandez le Pack Zero Sabot"
						: "Commande terminée"
				}
				onHide={() => hideDialog()}
			>
				<Loader visible={loader} />
				{!succes && !fail ? (
					<div className="modal">
						<div className="action row ">
							<button
								onClick={onPressOpenWhatsapp}
								className=" flex order whatsapp-btn"
							>
								Commandez via WhatsApp
								<i className="fab fa-xl fa-whatsapp icon"></i>
							</button>
						</div>
						<div className="notice">
							Pensez à avoir les clés avant d'être dans une
							situation critique. La livraison n'est pas
							instantanée
						</div>
						<div className="row col-2">
							<div className="input">
								<div className="label">Nom complet</div>
								<div className="field column">
									<input
										required
										type="text"
										value={order.fullname}
										onChange={(e) => {
											setOrder({
												...order,
												fullname: e.target.value,
											})
											setOrderError({
												...orderError,
												fullnameError: isEmpty(
													e.target.value
												),
											})
										}}
										name="fullname"
									/>
									<span hidden={!orderError.fullnameError}>
										Champs obligatoire
									</span>
								</div>
							</div>
							<div className="hdivider" />
							<div className="input">
								<div className="label">Téléphone</div>
								<div className="field column">
									<input
										required
										maxLength={10}
										placeholder="0666666666"
										type="text"
										name="phone"
										value={order.phone}
										onChange={(e) => {
											setOrder({
												...order,
												phone: e.target.value,
											})
											setOrderError({
												...orderError,
												phoneError:
													!phoneValidation.test(
														e.target.value
													),
											})
										}}
									/>
									<span hidden={!orderError.phoneError}>
										Téléphone invalide
									</span>
								</div>
							</div>
						</div>
						<div className="row col-2">
							<div className="input">
								<div className="label">Email</div>
								<div className="field column">
									<input
										required
										type="text"
										name="email"
										value={order.email}
										onChange={(e) => {
											setOrder({
												...order,
												email: e.target.value,
											})
											setOrderError({
												...orderError,
												emailError:
													!emailValidation.test(
														e.target.value
													),
											})
										}}
									/>
									<span hidden={!orderError.emailError}>
										Email invalide
									</span>
								</div>
							</div>

							<div className="hdivider" />

							<div className="input">
								<div className="label">Villes</div>
								<div className="field column">
									<div className="field column">
										<Dropdown
											options={cities}
											value={order.city}
											onChange={(e) => {
												setOrder({
													...order,
													city: e.value,
												})
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="input radios">
							<label className="radio">
								<input
									type="radio"
									value="Livraison"
									name="shmode"
									checked={order.shmode === "Livraison"}
									onChange={(e) => {
										setOrder({
											...order,
											shmode: e.target.value,
											adresse: "",
										})
										setDelivery(DELIVERY_PRICE)
									}}
								/>
								<span>Livraison</span>
								<img src={IMAGES.delivery} alt="" />
							</label>
							<label className="radio">
								<input
									type="radio"
									value="Retrait"
									name="shmode"
									checked={order.shmode === "Retrait"}
									onChange={(e) => {
										setOrder({
											...order,
											shmode: e.target.value,
											adresse: "Retrait au magasin",
										})
										setDelivery(0)
										setOrderError({
											...orderError,
											adresseError: false,
										})
									}}
								/>
								<span>Point de retrait</span>
								<img src={IMAGES.warehouse} alt="" />
							</label>
						</div>
						{order.shmode === "Livraison" && (
							<div className="input">
								<div className="label">
									Adresse de livraison
								</div>
								<div className="field column">
									<textarea
										name="adresse"
										required
										value={order.adresse}
										placeholder="Quartier, Rue, Résidence, Etage, Code Postal"
										rows="3"
										onChange={(e) => {
											setOrder({
												...order,
												adresse: e.target.value || "",
											})
											setOrderError({
												...orderError,

												adresseError:
													order.shmode ===
														"Livraison" &&
													isEmpty(e.target.value),
											})
										}}
									/>
									<span hidden={!orderError.adresseError}>
										Adresse obligatoire
									</span>
								</div>
							</div>
						)}

						<div className="input">
							<div className="label">Quantité</div>
							<div className="field row vcenter numericupdown">
								<div
									disabled={order.quantity <= 1}
									className="increment"
									onClick={() => updateQuantity(-1)}
								>
									-
								</div>
								<div className="qte"> {order.quantity} </div>
								<div
									disabled={order.quantity >= 10}
									className="increment"
									onClick={() => updateQuantity(1)}
								>
									+
								</div>
							</div>
						</div>

						<div className="resume">
							<div className="line">
								{order.quantity} x Clé {order.city}
							</div>
							<div className="line">
								Total :{" "}
								{order.quantity * pricesMap.get(order.city)} DH
							</div>

							{order.shmode === "Livraison" && (
								<div className="line">
									Livraison : {deliveryPrice} DH
								</div>
							)}

							<div className="line">
								Montant à payer :{" "}
								{order.quantity * pricesMap.get(order.city) +
									deliveryPrice}{" "}
								DH
							</div>
						</div>

						{order.shmode !== "Livraison" && (
							<div className="point-retrait">
								<div className="row-line">
									<div className="img">
										<img src={IMAGES.pizzeria} alt="" />
									</div>
									<div className="location-detail">
										<h1>Pizzeria Cappero Resto</h1>
										<div className="addr">
											84 Rue abou Marouane Abdelmalik,
											Quartier des Hopitaux.
										</div>

										<div className="loc">
											La localisation vous sera envoyé par
											e-mail
										</div>
									</div>
								</div>
								<div className="row-col">
									<p className="loc">06 70 66 05 33</p>
									<div className="loc">
										Ouvert Chaque jour de 12h00 jusqu'au
										00:00
									</div>
								</div>
							</div>
						)}

						<div className="action row">
							<button
								disabled={errorCheck()}
								className="primary flex order"
								type="submit"
								onClick={() => createOrder()}
							>
								Commander
							</button>
						</div>
					</div>
				) : succes && !fail ? (
					<div className="success-order animation-ctn">
						<div className="icon icon--order-success svg">
							<Checkmark />
						</div>

						<h1>Votre commande a été enregistrée avec succès.</h1>
						<p>
							Le livreur vous contactera dans un délai de 24-48h
							pour vous livrer si vous avez choisi d'être livré.
						</p>
						<p>
							Dans le cas échéant, la localisation et l'adresse du
							point de retrait vous seront envoyés par e-mail.
						</p>

						<div className="action row">
							<button
								className="primary flex order"
								type="submit"
								onClick={() => closeOrder()}
							>
								Fermer
							</button>
						</div>
					</div>
				) : (
					<div className="fail-order animation-ctn">
						<div className="icon checkmark-fail svg">
							<div className="circle">X</div>
						</div>

						<h1>Vous ne pouvez pas commander pour l'instant !</h1>
						<p>
							Pour trouver de l'aide, contactez nous sur notre
							page Facebook.
						</p>

						<div className="action row">
							<button
								className="primary flex order"
								type="submit"
								onClick={() => closeOrder()}
							>
								Fermer
							</button>
						</div>
					</div>
				)}
			</Dialog>
		</Fragment>
	)
}

export default OrderModal
